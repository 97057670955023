<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Liste des entreprises
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="$router.push({name:'business-contact-new'})"
        >
          Nouvelle Enterprise
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="businessContacts"
          no-data-text="Pas d'entreprise, créez en une"
        >
          <!-- EMPLOYES -->
          <template #[`item.employee`]="{item}">
            <div
              v-if="item.employee"
              class="flex-column text-center"
            >
              {{ item.contacts.length }}
            </div>
          </template>
          <!-- END EMPLOYES -->
          <!-- EMPLOYES -->
          <template #[`item.managers`]="{item}">
            <div
              v-if="item.managers"
              class="flex-column text-center"
            >
              <business-contact-manager-chip
                v-if="getManagers(item).length > 0"
                :managers="getManagers(item)"
                :business-contact-name="item.name"
                :business-contact-id="item._id"
              ></business-contact-manager-chip>
              <v-chip
                v-else
              >
                Pas de gérant
              </v-chip>
            </div>
          </template>
          <!-- END EMPLOYES -->
          <!-- OPCO -->
          <template #[`item.opcoInfos`]="{item}">
            <div
              v-if="item.opcoInfos"
              class="flex-column text-center"
            >
              {{ item.opcoInfos.operateurdecompetence }}
            </div>
          </template>
          <!-- END OPCO -->
          <!-- ACTIONS -->
          <template #[`item.actions`]="{item}">
            <v-btn
              color="success"
              @click="goToBusinessContact(item)"
            >
              Voir fiche
            </v-btn>
          </template>
          <!-- END OPCO -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

import BusinessContactManagerChip from './BusinessContactManagerChip.vue'

export default {
  components: {
    BusinessContactManagerChip,
  },
  metaInfo: {
    title: 'Liste des entreprises',
    titleTemplate: '%s - Jimbo',
  },
  data: () => ({
    businessContacts: [],
    search: '',
    headers: [
      {
        text: 'Nom de l\'entreprise',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Siret',
        align: 'center',
        sortable: true,
        value: 'siret',
      },
      {
        text: 'Taille de l\'entreprise',
        align: 'center',
        sortable: true,
        value: 'businessSize',
      },
      {
        text: 'Employés',
        align: 'center',
        sortable: true,
        value: 'employee',
      },
      {
        text: 'Gérant',
        align: 'center',
        sortable: true,
        value: 'managers',
      },
      {
        text: 'OPCO',
        align: 'center',
        sortable: true,
        value: 'opcoInfos',
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'actions',
      },
    ],
    icons: {
      mdiBriefcase,
    },
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchBusinessContacts()])
        .then(values => {
          [this.businessContacts] = values
        })
    },
    async fetchBusinessContacts() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/business-contact/all/`)

      return response.data
    },
    goToBusinessContact(bc) {
      this.$router.push({ name: 'business-contact-single', params: { id: bc._id } })
    },
    getManagers(businessContact) {
      return businessContact.contacts.filter(x => x.status === 'manager')
    },
  },
}
</script>

<style>

</style>
