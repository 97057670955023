<template>
  <div>
    <v-chip
      outlined
      color="primary"
      @click="managerDialog = true"
    >
      {{ managers[0].firstName }}
      {{ managers[0].lastName }}
    </v-chip>
    <span
      v-if="managers.length > 1"
      class="grey--text text-caption"
    >
      (+{{ managers.length - 1 }} autres)
    </span>
    <v-dialog
      v-model="managerDialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ managers[0].firstName }}
              {{ managers[0].lastName }}
            </h3>
          </div>
        </v-card-title>
        <v-card-subtitle>
          Gérant chez    <router-link :to="{name:'business-contact-single', params: {id: businessContactId}}">
            {{ businessContactName }}
          </router-link>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="managers[0].phone"
                outlined
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-center"
            >
              <v-btn
                :href="`tel:${managers[0].phone}`"
                icon
                outlined
                color="primary"
              >
                <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-center"
            >
              <v-btn
                icon
                color="primary"
                outlined
                @click="copyClip(managers[0].phone)"
              >
                <v-icon>{{ icons.mdiClipboard }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="managers[0].email"
                readonly
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-center"
            >
              <v-btn
                :href="`tel:${managers[0].phone}`"
                icon
                outlined
                color="primary"
              >
                <v-icon>{{ icons.mdiEmail }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-center"
            >
              <v-btn
                icon
                color="primary"
                outlined
                @click="copyClip(managers[0].phone)"
              >
                <v-icon>{{ icons.mdiClipboard }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            outlined
            color="primary"
          >
            Voir la fiche
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPhoneOutline, mdiClipboard, mdiEmail } from '@mdi/js'

export default {
  props: {
    businessContactName: {
      type: String,
      default: 'Entreprise',
    },
    businessContactId: {
      type: String,
      default: '',
    },
    managers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    managerDialog: false,
    icons: {
      mdiPhoneOutline,
      mdiClipboard,
      mdiEmail,
    },
  }),
  methods: {
    copyClip(e) {
      navigator.clipboard.writeText(e)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'info',
            text: 'Copié dans le presse-papier',
            value: true,
          })

          return true
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la copie',
            value: true,
          })
          console.log(err)
        })

      return false
    },
  },
}
</script>

<style>

</style>
